import { useState, useEffect } from "react";
import { Static } from "./Static";
import { Tiles } from "./Tiles";
import { Video } from "./Video";
import { Footer } from "./Footer";
import { CountDown } from "./CountDown";
import { Legal } from "./Legal";

export const App = () => {
  const [appState, setAppState] = useState("LOADING");
  const [config, setConfig] = useState();
  const target = new Date(Date.UTC(2024, 8, 4, 15, 30)).getTime();

  useEffect(() => {
    fetch("./config.json?10")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        setTimeout(() => {
          setAppState("LOADED");
        }, 5000);
      });
  }, []);

  return (
    <>
      <div className="w-screen h-screen min-h-[700px] sm:flex flex-col justify-center items-center bg-[#e6ddd4] overflow-hidden">
        <div className="m-auto w-full">
          <div
            className={`${
              appState === "LOADING" ? "opacity-0" : "opacity-100"
            } transition-opacity duration-1000 h-[170px] overflow-auto pt-5`}
          >
            <Tiles items={config?.items} activeItemIndex={config?.activeItemIndex} />
          </div>
          <div className="px-5">
            {appState === "LOADING" || !config.videoId ? (
              <Static fadeIn />
            ) : (
              <Video videoId={config.videoId} autoPlay={config.autoPlay} mute={config.mute} />
            )}
          </div>
          <div
            className={`${
              appState === "LOADING" ? "opacity-0" : "opacity-100"
            } transition-opacity duration-1000 h-[136px]`}
          >
            {/* <CountDown target={target} /> */}
            <Footer />
          </div>
        </div>
      </div>
      <Legal />
    </>
  );
};
