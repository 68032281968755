export const Legal = () => (
  <div className="bg-black text-white text-[10px] text-center p-2">
    © 2024 Sony Music Entertainment
    <br />
    <a
      href="http://www.sonymusic.com/privacy/termsandconditions.html"
      target="_blank"
      className="sme-privacy-exclude"
    >
      Terms&nbsp;and&nbsp;Conditions
    </a>
    &nbsp;|&nbsp;
    <a
      href="http://www.sonymusic.com/privacypolicy.html"
      target="_blank"
      className="sme-privacy-exclude"
    >
      Privacy&nbsp;Policy
    </a>
    &nbsp;|&nbsp;
    <a target="_blank" href="https://www.sonymusic.com/how-we-use-your-data/">
      How We Use Your Data
    </a>
    &nbsp;|&nbsp;
    <a
      target="_blank"
      href="https://www.sonymusic.com/ccpa-contact-form/"
      className="sme-privacy-exclude"
    >
      Do Not Sell My Personal Information
    </a>
    &nbsp;|&nbsp;
    <a
      target="_blank"
      href="https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights"
      className="sme-privacy-exclude"
    >
      Your California Privacy Rights
    </a>
    &nbsp;|&nbsp;
    <a href="https://www.sonymusic.com/feedback/" target="_blank" className="sme-privacy-exclude">
      Send&nbsp;Feedback
    </a>
  </div>
);
