export const Video = ({ videoId, autoPlay, mute }) => (
  <div className="border-2 border-black rounded-lg overflow-hidden my-10 aspect-video w-full max-w-[680px] bg-gray-300/50 m-auto">
    <iframe
      width="680"
      height="382"
      src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=${autoPlay}&mute=${mute}&rel=0`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      className="w-full h-full"
    ></iframe>
  </div>
);
