import { useRef, useEffect } from "react";

export const Tiles = ({ items, activeItemIndex }) => {
  const containerRef = useRef();

  useEffect(() => {
    // containerRef.current.scrollLeft = 300;
  });

  return (
    <div
      ref={containerRef}
      className={`w-full text-center ${activeItemIndex >= 0 ? "min-w-[710px]" : "min-w-[700px]"}`}
    >
      {items?.map(({ index, icon, image, link, text }) => (
        <div
          key={index}
          className={`inline-flex flex-col shrink-0 justify-between mx-2 border-dashed border-black rounded-lg border-2 overflow-hidden ${
            activeItemIndex === index ? "w-40 h-40" : "w-[120px] h-[120px] relative"
          } ${image ? "top-[12px]" : "p-2 top-[20px]"}`}
        >
          {image ? (
            <a href={link} target="_blank" className="block relative">
              <img className="absolute inset-0 block w-full" src={image} alt="Album cover" />
            </a>
          ) : (
            <>
              <div></div>
              <div className="flex justify-center">
                <img
                  className={activeItemIndex === index ? "h-[90px]" : "h-[60px]"}
                  src={icon}
                  alt="Symbol"
                />
              </div>
              <div
                className={`text-center uppercase ${
                  activeItemIndex === index ? "text-lg tracking-widest" : "text-sm tracking-wide"
                }`}
              >
                {text}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
