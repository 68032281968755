export const Static = ({ fadeIn = false }) => (
  <div className="flex items-center justify-center relative border-2 border-black rounded-lg overflow-hidden my-10 aspect-video w-full max-w-[680px] m-auto">
    <img
      className="absolute inset-0 w-full h-100 opacity-30"
      src="./assets/419bcf18-7305-45e0-8af0-5c7a005a34d2.gif"
    />
    <img
      className={`relative w-4/5 sm:mb-10 opacity-0 duration-[5s] ${fadeIn ? "" : "opacity-100"}`}
      src="./assets/bb5e9971-752b-40f5-aa2c-12fc951ad0d7.png"
      onLoad={(e) => (e.target.className += " opacity-100")}
    />
  </div>
);
