import { useState, useEffect, useRef } from "react";

export const Footer = () => {
  const sm = useRef();
  const [preAddOnAppleMusicState, setPreAddOnAppleMusicState] = useState("button");
  const [preAddOnAppleMusicEmailAddress, setPreAddOnAppleMusicEmailAddress] = useState("");

  useEffect(() => {
    // eslint-disable-next-line no-undef
    sm.current = new SMEAppleMusic({
      ae: {
        ae: "6f031bfdb1f3b11f33997bd5fbf79af9c610f313cdae47526986a5c48a00888d",
        brand_id: "3450842",
        segment_id: "2256472",
        activities:
          '{"actions":{"presave":150601},"mailing_list_optins":{"a0S0800000tmvT5EAI":150602}}',
      },
      am: {
        dev_token: "",
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: [],
        },
      },
      sf: {
        form: "484774",
        default_mailing_list: "",
      },
      smf: {
        campaign_id: "484774",
        campaign_key: "71a8726bb6fb52f95a52b55039702486",
      },
    });
  }, []);

  const handleSubmitPreAddOnAppleMusic = (e) => {
    e.preventDefault();

    sm.current
      .doActions(preAddOnAppleMusicEmailAddress, false, ["a0S0800000tmvT5EAI"], false)
      .then(function (res) {
        setPreAddOnAppleMusicState("thanks");
      })
      .catch(function (err) {
        setPreAddOnAppleMusicState("error");
      });
  };

  return (
    <div className="text-center px-5">
      <a
        className="inline-block w-56 h-[40px] m-2 bg-[#231f20] border-2 border-[#231f20] text-white px-5 py-2 uppercase text-sm font-light tracking-wide rounded-lg hover:bg-[#e6ddd4] hover:text-[#231f20] transition-colors"
        href="https://halsey.lnk.to/thegreatimpersonator"
        target="_blank"
      >
        <span className="fas fa-shopping-cart" /> Pre-order Now
      </a>

      <a
        className="inline-block w-56 h-[40px] m-2 bg-[#231f20] border-2 border-[#231f20] text-white px-5 py-2 uppercase text-sm font-light tracking-wide rounded-lg hover:bg-[#e6ddd4] hover:text-[#231f20] transition-colors"
        href="https://presaves.sonymusicfans.com/?campaign_id=484774&mailing_list_ids=a0S0800000tmvT5EAI&triggered_sends=&retargeting_consent=0&source_channel=Spotify"
      >
        <span className="fab fa-spotify" /> Pre-save on Spotify
      </a>

      {preAddOnAppleMusicState === "button" && (
        <button
          className="inline-block w-56 h-[40px] m-2 bg-[#231f20] border-2 border-[#231f20] text-white px-5 py-2 uppercase text-sm font-light tracking-wide rounded-lg hover:bg-[#e6ddd4] hover:text-[#231f20] transition-colors"
          onClick={() => setPreAddOnAppleMusicState("form")}
        >
          Pre-add on <span className="fab fa-apple" /> Music
        </button>
      )}
      {preAddOnAppleMusicState === "form" && (
        <form
          className="inline-flex m-2 border-[#231f20] border-2 rounded-lg w-56 h-[40px] overflow-hidden"
          onSubmit={handleSubmitPreAddOnAppleMusic}
        >
          <input
            type="text"
            className="w-full px-2 text-sm outline-0"
            placeholder="EMAIL ADDRESS"
            value={preAddOnAppleMusicEmailAddress}
            onChange={({ target }) => setPreAddOnAppleMusicEmailAddress(target.value)}
            required
          />
          <button
            className="bg-[#231f20] text-white px-2 uppercase text-sm ml-auto hover:bg-[#e6ddd4] hover:text-[#231f20] transition border-l-2 border-[#231f20]"
            type="submit"
          >
            Go
          </button>
        </form>
      )}
      {preAddOnAppleMusicState === "thanks" && (
        <div
          className="inline-block w-56 h-[40px] m-2 border-2 border-[#231f20] px-5 py-2 uppercase text-sm font-light tracking-wide rounded-lg bg-[#e6ddd4] text-[#231f20]"
          onClick={() => setPreAddOnAppleMusicState("form")}
        >
          Successfully Added
        </div>
      )}
      {preAddOnAppleMusicState === "error" && (
        <div
          className="inline-block w-56 h-[40px] m-2 border-2 border-[#231f20] px-5 py-2 uppercase text-[11px] leading-5 font-light tracking-wide rounded-lg bg-[#e6ddd4] text-[#231f20]"
          onClick={() => setPreAddOnAppleMusicState("form")}
        >
          Something's gone wrong
        </div>
      )}

      {/* <br />
      <a
        href="https://halsey.lnk.to/text_me"
        target="_blank"
        className="inline-block w-56 h-[40px] m-2 border-2 border-[#231f20] px-5 py-2 uppercase text-sm font-light tracking-wide rounded-lg bg-[#e6ddd4] text-[#231f20] transition hover:bg-[#231f20] hover:text-white"
      >
        Message me for clues
      </a> */}
    </div>
  );
};
